import { GetAccessTokenError } from "./GetAccessTokenError";

/**
 * An timeout error thrown when attempting to get an access token
 * @extends GetAccessTokenError
 */
export class GetAccessTokenTimeoutError extends GetAccessTokenError {
  /**
   * create a GetAccessTokenTimeoutError
   * @param {*} error - original error
   * @param  {...any} params - other params given on construction
   */
  constructor(error, ...params) {
    super(error, ...params);
    this.name = "GetAccessTokenTimeoutError";
  }
}
