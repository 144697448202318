import {
  getApiOriginError,
  getGetAccessTokenError,
  getInteractionCallbackError,
  getGetAccessTokenTimeoutError,
  getLoggerError,
  getServerRequestLoggerError,
  getRetriesError,
  getRetryDelayError,
  getEventBusError
} from "./errors";

const validateConfiguration = ({
  getAccessToken,
  getAccessTokenTimeout,
  apiOrigin,
  interactionCallback,
  logger,
  serverRequestLogger,
  retries,
  retryDelay,
  eventBus
} = {}) => {
  const errors = []
    .concat(getGetAccessTokenError(getAccessToken))
    .concat(getGetAccessTokenTimeoutError(getAccessTokenTimeout))
    .concat(getApiOriginError(apiOrigin))
    .concat(getInteractionCallbackError(interactionCallback))
    .concat(SERVER ? getLoggerError(logger) : undefined)
    .concat(
      SERVER ? getServerRequestLoggerError(serverRequestLogger) : undefined
    )
    .concat(getRetriesError(retries))
    .concat(getRetryDelayError(retryDelay))
    .concat(getEventBusError(eventBus))
    .filter(e => e !== undefined);

  if (errors.length) {
    throw new TypeError(errors.join("\n"));
  }
};

export default validateConfiguration;
