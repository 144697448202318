import { GetAccessTokenError, GetAccessTokenTimeoutError } from "./errors";
let getAccessTokenPromise = null;

const singletonGetAccessTokenHandler = async ({
  getAccessToken,
  getAccessTokenTimeout = 8000
}) => {
  let timeout;
  try {
    if (!getAccessTokenPromise) {
      getAccessTokenPromise = (async () => {
        try {
          return await getAccessToken();
        } catch (e) {
          throw new GetAccessTokenError(new Error(e));
        }
      })();
    }

    return await Promise.race([
      new Promise((_, reject) => {
        timeout = setTimeout(() => {
          reject(
            new GetAccessTokenTimeoutError(
              new Error(`timeout of ${getAccessTokenTimeout}ms met`)
            )
          );
        }, getAccessTokenTimeout);
      }),
      getAccessTokenPromise
    ]);
  } finally {
    clearTimeout(timeout);
    getAccessTokenPromise = null;
  }
};

export default singletonGetAccessTokenHandler;
