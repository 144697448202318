import { sortOrders, sorts, DEFAULT_LIST_ITEMS_URL_PATH } from "../constants";
import validateGetParams from "../validateGetParams";
import validateRequestOptions from "../validateRequestOptions";
import validateGetAccessTokenDelegate from "../validateGetAccessTokenDelegate";
/**
 * Get a customers saved items
 * @memberof module:sdkInstance
 * @inner
 * @param {Object} params
 * @param {integer} [params.offset=0] offset
 * @param {integer} [params.limit=30] limit
 * @param {asos.customer.savedItemsSdk.sorts} [params.sort=SORTBY_DATE] sort direction
 * @param {asos.customer.savedItemsSdk.sortOrders} [params.sortOrder=SORT_ORDER_DESCENDING] sort order
 * @param {asos.customer.savedItemsSdk.expands} [params.expand] expand parameter
 * @param {requestOptions} [params.requestOptions] request options
 * @return {Promise<external:API.SavedItemProductResponse>} Promise resolving to SavedItemProductResponse.
 * @throws {TypeError} configuration.{@link getAccessTokenDelegate|getAccessToken} is required, since this is an authorised endpoint.
 * @throws {RangeError} offset must be between 0 and 9999.
 * @throws {RangeError} limit must be between 0 and 200.
 * @throws {RangeError} sort must be a member of {@link asos.customer.savedItemsSdk.sorts}, if supplied.
 * @throws {RangeError} sortOrder must be a member of {@link asos.customer.savedItemsSdk.sortOrders}, if supplied.
 * @throws {RangeError} expand must be a member of {@link asos.customer.savedItemsSdk.expands}, if supplied
 * @throws {GetAccessTokenError} error getting an access token from the getAccessToken delegate.
 * @throws {GetAccessTokenTimeoutError} timeout hit when attempting to get an access token from the getAccessToken delegate.
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Get Saved Items|API Documentation}
 * @example
 * sdk.getItems({ offset: 100, limit: 50, sort: SORTBY_BRAND, sortOrder: SORT_ORDER_ASCENDING });
 */
const getItems = (
  { api, context, configuration: { getAccessToken } = {} },
  {
    offset = 0,
    limit = 30,
    sort = sorts.SORTBY_DATE,
    sortOrder = sortOrders.SORT_ORDER_DESCENDING,
    expand,
    requestOptions
  } = {}
) => {
  validateGetAccessTokenDelegate(getAccessToken);
  validateGetParams({ offset, limit, sort, sortOrder, expand });
  validateRequestOptions(requestOptions);
  return api.get({
    path: DEFAULT_LIST_ITEMS_URL_PATH,
    queryParams: {
      offset,
      limit,
      sort,
      sortOrder,
      ...(expand ? { expand } : {}),
      ...context
    },
    requestOptions
  });
};

export default getItems;
