import validateItem from "./validateItem";
import addItems from "../addItems";

/**
 * Add an item to saved items
 * @memberof module:sdkInstance
 * @inner
 * @param {Object} params
 * @param {Object} params.item item to add to saved items. one of colourWayId or variantId is required.
 * @param {number} params.item.productId product id
 * @param {number} [params.item.colourWayId] colour way id
 * @param {number} [params.item.variantId] variant id
 * @param {requestOptions} [params.requestOptions] request options
 * @return {Promise<external:API.ItemAddedResponse>} Promise resolving to ItemAddedResponse.
 * @throws {TypeError} only one of variantId or colourWayId should be supplied.
 * @throws {TypeError} configuration.{@link getAccessTokenDelegate|getAccessToken} is required, since this is an authorised endpoint.
 * @throws {GetAccessTokenError} error getting an access token from the getAccessToken delegate.
 * @throws {GetAccessTokenTimeoutError} timeout hit when attempting to get an access token from the getAccessToken delegate.
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Add Saved Item(s)|API Documentation}
 * @example
 * sdk.addItem({ item: { productId: 12345, colourWayId: 67890 } });
 */
const addItem = (apiContextAndConfiguration, { item, requestOptions } = {}) => {
  validateItem(item);
  return addItems(apiContextAndConfiguration, {
    items: [item],
    requestOptions
  });
};

export default addItem;
