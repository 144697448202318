import singletonGetAccessTokenHandler from "./singletonGetAccessTokenHandler";

const authorisation = async ({ getAccessToken, getAccessTokenTimeout }) => ({
  Authorization: `Bearer ${await singletonGetAccessTokenHandler({
    getAccessToken,
    getAccessTokenTimeout
  })}`
});

export default authorisation;
