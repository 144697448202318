/**
 * An error thrown when attempting to get an access token
 * @extends Error
 */
export class GetAccessTokenError extends Error {
  /**
   * create a GetAccessTokenError
   * @param {*} error - original error
   * @param  {...any} params - other params given on construction
   */
  constructor(error, ...params) {
    super(error, ...params);
    const message = error && error.message ? error.message : "";
    this.message = message;
    this.name = "GetAccessTokenError";
    /* istanbul ignore next */
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GetAccessTokenError);
    }
  }
}
