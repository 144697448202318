export const getGetAccessTokenErrorMessage =
  "configuration.getAccessToken should be a function if defined";

export const getApiOriginErrorMessage =
  "configuration.apiOrigin should be a string if defined";

export const getInteractionCallbackErrorMessage =
  "configuration.interactionCallback should be a function if defined";

export const getLoggerErrorMessage =
  "configuration.logger is required, and should be an instance of asos-web-logging";

export const getServerRequestLoggerErrorMessage =
  "configuration.serverRequestLogger should be a function if defined";

export const getRetriesErrorMessage =
  "configuration.retries should be a positive integer if defined";

export const getRetryDelayErrorMessage =
  "configuration.retryDelay should be a function if defined";

export const getEventBusErrorMessage =
  "configuration.eventBus should be an eventBus instance if defined";

export const getAccessTokenTimeoutErrorMessage =
  "configuration.getAccessTokenTimeout should be a positive integer if defined";
