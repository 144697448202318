import validateItemId from "./validateItemId";
import validateRequestOptions from "../validateRequestOptions";
import validateGetAccessTokenDelegate from "../validateGetAccessTokenDelegate";
import { DEFAULT_LIST_ITEMS_URL_PATH } from "../constants";

/**
 * Remove item from saved items
 * @memberof module:sdkInstance
 * @inner
 * @param {Object} params
 * @param {string} params.itemId saved item id to remove
 * @param {requestOptions} [params.requestOptions] request options
 * @return {Promise} Promise resolving to no content.
 * @throws {TypeError} itemId should be a valid guid.
 * @throws {TypeError} configuration.{@link getAccessTokenDelegate|getAccessToken} is required, since this is an authorised endpoint.
 * @throws {GetAccessTokenError} error getting an access token from the getAccessToken delegate.
 * @throws {GetAccessTokenTimeoutError} timeout hit when attempting to get an access token from the getAccessToken delegate.
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Delete saved item|API Documentation}
 * @example
 * sdk.removeItem({ itemId: "55596cc6-1799-4e6c-b5ff-009928d35ab6" });
 */
const removeItem = (
  { api, configuration: { getAccessToken } = {} },
  { itemId, requestOptions } = {}
) => {
  validateGetAccessTokenDelegate(getAccessToken);
  validateItemId(itemId);
  validateRequestOptions(requestOptions);
  return api.delete({
    path: `${DEFAULT_LIST_ITEMS_URL_PATH}/${itemId}`,
    requestOptions
  });
};

export default removeItem;
