import isPositiveInteger from "../../../isPositiveInteger";
import { getAccessTokenTimeoutErrorMessage as errorMessage } from "./constants";

const getGetAccessTokenTimeoutError = getAccessTokenTimeout => {
  if (
    getAccessTokenTimeout !== undefined &&
    !isPositiveInteger(getAccessTokenTimeout)
  ) {
    return errorMessage;
  }
};

export default getGetAccessTokenTimeoutError;
